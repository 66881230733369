<template>
  <div :data-testid="dataTestid" v-bind="$attrs" :class="[
      'level md:flex md:items-center md:justify-between',
      '*:has-[.level-item]:only:grow',
      props.isMobile && 'flex items-center justify-between *:has-[.level-item]:only:mb-0',
      ]">
    <slot />
  </div>
</template>

<script setup lang="ts">

type Props = {
  isMobile?: boolean
  dataTestid?:string;
}

const props = withDefaults(defineProps<Props>() , {
  dataTestid:'organism-level'
});

</script>
